.ajTrackerCommand {
    color: red;
}

.ajTrackerSectionHeader {
    font-size: large;
    width: 100%;
    border-bottom: 1px solid gainsboro;
}

.ajTrackerSectionSubHeader {
    color: gray;
    width: 100%;
    text-transform: uppercase;
    font-weight: 600;
    font-size: medium;
}

.ajTrackerHTML {
    color: gray;
    background-color: white;
    font-size: small;
    padding: 20px;
    width: calc(100% - 20px);
    border: 1px solid gray;
    border-radius: 5px;
    margin: 10px;
    word-break: break-all;
}

.ajTrackerSectionImage {
    max-height: 400px;
    max-width: calc(100% - 5px);
}

.ajTrackerSectionInfoRow {
    display: flex;
    align-items: center;
    &__itemLeft {
        min-width: 200px;
        font-weight: 700;
    }
    &__itemRight {
        width: 100%;
    }
    @media(max-width: $desktopBreakPoint) {
        flex-direction: column;
        align-items: flex-start;
    }
}
