.customControl {
    &__dataLine {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__dataControl {
            width: 100%;
        }
    }
}

.controlInlineBlockContainer {
    display: flex !important;
}

.nudeButton {
    outline: 0;
    border: 0;
    background-color: transparent;    
}

.everleadInfoLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    input { width: 100%; }
    select { width: 100%; }
    i { margin: 0 5px; }
}

.toLowercase {
    text-transform: lowercase;
}