@import "variables";

.wizardLabel {
    margin: 0;
    padding: 0;
    font-weight: 700;
    flex: 1 0 150px;

    &__header {
        width: 100%;
        font-weight: bold;
    }

    &__additionalInfo {
        margin-top: 20px;
    }

    @media (min-width: $desktopBreakPoint) {
        min-width: 350px;
    }
    @media (max-width: $desktopBreakPoint) {
        max-height: 30px;
    }
}


.wizardCheckBox {
    outline: 0;
}

.wizardDropDown {
    @media (max-width: $desktopBreakPoint) {
        width: 100%;
    }

    @media (min-width: $desktopBreakPoint) {
        width: calc(100% - 370px);
    }
}

.wizardInput {
    border-width: 0 0 1px 0;
    outline: 0;

    @media (max-width: $desktopBreakPoint) {
        width: 100%;
    }

    @media (min-width: $desktopBreakPoint) {
        width: calc(100% - 370px);
    }
}

.wizardInputSmall {
    border-width: 0 0 1px 0;
    outline: 0;

    @media (max-width: $desktopBreakPoint) {
        width: 100%;
    }

    @media (min-width: $desktopBreakPoint) {
        width: calc(100% - 370px);
    }
}

i {
    margin-left: 5px;
}

.wizardLine {
    display: flex;
    align-items: center;
}
.wizardLine:hover {
    background-color: whitesmoke;
}

.wizardItemWrapper {
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
    padding: 15px 25px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    &__mocoContainer {
        margin-top: 10px;
        border-top: 1px solid gainsboro;
    }

    &__label {
        margin: 0;
        padding: 0;
    }

    &__headerBox {
        margin: 10px 0 !important;
    }

    &__newItemIcon {
        margin-right: 10px;
    }

    &__translations {
        position: absolute;
        right: 100px;
        top: -3px;
        height: 50px;
        border: 1px solid gray;
        border-radius: 3px 3px 15px 15px;

        width: 50px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;

        &__icon {
            margin: 0;
            padding: 0;
        }
    }

    &__inactive {
        background-color: whitesmoke;
    }
}

.wizardTranslations {
    &__editor {
        z-index: 2001;
        background-color: whitesmoke;
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 300px;
        width: 50vw;
        height: 50vh;
        min-height: 600px;

        &__mode3 {
            padding: 0 5px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            &__active {
                color: $secondary !important;
            }
        }
        &__header {
            height: 60px;
            background-color: gainsboro;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 10px 20px;
            font-size: x-large;
            border-bottom: 1px solid gray;

            &__active {
                // fill: $greenColor;
                // color: $greenColor;
                background-color: $secondary;
            }
        }

        &__content {
            padding: 20px;
            height: calc(100% - 60px);
            overflow-y: auto;
            border: 1px solid gainsboro;
            &__label {
                width: 200px;
            }

            &__input {
                width: calc(100% - 210px);

                input {
                    border-width: 0 0 1px 0;
                    outline: 0;
                    width: 100%;
                }
            }

            &__header {
                font-size: larger;
                font-weight: 700;
                margin: 10px 0;
            }

            &__row {
                display: flex;
                flex-direction: row;
            }
        }
    }

    &__overlay {
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 100vw;
        background-color: rgba(0,0,0, 0.5);
        z-index: 2000;
    }
}

.wizardSubSectionHeader {
    padding: 5px;
    background-color: $blueColor;
    color: white;
    width: calc(100% - 50px);
    margin-bottom: 10px;
}

.wizardSectionSpacer {
    margin-top: 3px;
}

.wizardDetailItem {
    margin-top: 15px;
    background-color: white;
    padding: 10px;
    margin: 0 0px 10px 0;
    box-shadow: none !important;
    // border:1px solid red;

    @media (max-width: $desktopBreakPoint) {
        width: 100%;
        display: flex;
        flex-direction: column; 
    }
    &__input {
        width: calc(100% - 5px);
    }

    &__button {
        background-color: $blueColor;
        color: white;
        width: 200px;
        text-overflow: ellipsis;
        overflow-x: hidden;
        border: 0px;
        outline: 0;
        white-space: nowrap;
    }

    &__button:hover {
        background-color: $secondary;
    }
    &__button:disabled {
        background-color: gainsboro !important;
        color: black;
    }
    select { outline: 0; }
}

.wizardItemBlock {
    margin-bottom: 15px;
}

.wizardSubheader {
    padding: 0 0 20px 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid gainsboro;
    @media (max-width: $desktopBreakPoint) {
        padding: 0 20px 10px 20px;
        margin-bottom: 20px;
    }
    &__wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__headerLabel {
        width: 100%;
        font-weight: 700;
        margin: 0;
    }
    &__descriptionLabel {
        width: 100%;
        font-size: small;
        margin: 0;
    }
    &__button {
        outline: 0;
        border: 0;
        background-color: $blueColor;
        color: white;
        height: 25px;
        border-radius: 5px;
        margin: 5px 0;
    }
}

.wizardPageContent {
    overflow-y: auto;
    overflow-x: hidden;
}



.wizardEMail {
    &__wrapper {
        display: flex;
        height: 100%;
    }

    &__emailEditBar {
        padding: 0px;

        &__headerLabel {
            font-weight: bold;
            margin: 0 15px 0 0;
        }

        &__item {
            position: relative;
            display: flex;
            flex-direction: row;
            min-width: 0;
            word-wrap: break-word;
            background-color: #fff;
            background-clip: border-box;
            border: 1px solid rgba(0, 0, 0, 0.125);
            border-radius: 0.25rem;
            padding: 10px;
            margin-bottom: 5px;

            &__flex {
                display: flex;
                flex-direction: row;
                align-items: center;
                label { margin: 0 5px; }
            }
        }
    }

    &__addons {
        min-width: 400px;
        max-width: 400px;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        margin-left: 20px;
        &__content {
            height: 100%;
            overflow-y: auto;
            &__flexBox {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                &__button {
                    width: calc(100% - 5px);
                    margin: 5px 0;
                }
            }
        }
    }

    &__emailFlex {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

    }

    &__placeholder {
        border: 1px solid gainsboro;
        padding: 5px 5px 5px 10px;
        margin-bottom: 2px;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        i { margin-right: 5px; }
    }

    &__wizardEmailEditor {
        border: 1px solid gainsboro;
        outline: 0;
        width: 100%;
        height: calc(100% - 30px);
        overflow-y: auto;
    }
}

.maxHeight {
    height: 100%;
}

.wizardPage {
    height: calc(100% - 70px);
    &__mailEditor {
        height: calc(100% - 50px);
    }
}

.processWorkflowStepresultDetailDropdown  {
    @media (max-width: $desktopBreakPoint) {
        width: 100% !important;
    }   
}



.everleadSelectWrapper{
    position: relative;
    width:100%;


    &.disabled{
        color:$fontDark !important;
    }

    &.error{
        color:$functionRedColor !important;
    }


    &__label{
        position:relative;
        //top:12px;
        top:14px;
        left:8px;
        color:$fontBlack !important;
        font-size: 12px;
        font-weight: 400;
        padding: 0 0.2rem;
        margin: 0;
        background-color: $primaryLight;
        text-transform: uppercase;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
        //text-wrap: wrap;
        //display:flex;

        &.disabled{
            color: $disabledGray !important;
            font-weight: 700;
        }

        &.error{
            color:$functionRedColor !important;
        }
    }


    &__valueWrapper{
        border: $globalBorder;
        border-radius: $globalBorderRadius;
        display:flex;
        align-items: center;
        padding:10px 8px 10px 8px;
        height:30px;
        width:100%;
        background-color: $primaryLight;

        &:hover{
            border:$globalBorderHover;
        }

        &.error{
            border:1px solid $functionRedColor;
        }

        &.disabled{
            border:$globalBorder;
            cursor:not-allowed;
        }

        .select__box{
            position: relative;
            display: inline-block;
            width: 100%;
             

            select{
                width:100%;
                padding-right:30px;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                border: none;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgZmlsbD0iIzU0NjVmZiIgdmlld0JveD0iMCAwIDI1NiAyNTYiPjxwYXRoIGQ9Ik0yMTMuNjYsMTAxLjY2bC04MCw4MGE4LDgsMCwwLDEtMTEuMzIsMGwtODAtODBBOCw4LDAsMCwxLDUzLjY2LDkwLjM0TDEyOCwxNjQuNjlsNzQuMzQtNzQuMzVhOCw4LDAsMCwxLDExLjMyLDExLjMyWiI+PC9wYXRoPjwvc3ZnPg==');
                background-repeat:no-repeat;
                background-position: right 10px center;
                background-size: 12px 12px;
                cursor:pointer;
                font-size: 14px;
                color: $fontDark;

                &:disabled{
                    cursor: not-allowed;
                    color:$disabledGray !important;
                }
            }

            select::-ms-expand{
                display:none
            }

            select:focus{
                outline: none;
            }

        

        }

        .select__box:focus{
            outline:none;
        }


    }
}