.documentationButton {
    position: absolute;
    right: 0;
    top: 0;
    width: 25px;
    height: 25px;
    border: 1px solid gainsboro;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    
    i {
        margin: 0;
        padding: 0;
    }
}