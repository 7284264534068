@import "variables";

.uiControlEditor {
    &__subItem:hover {
        background-color: whitesmoke;
    }
    &__subItem {
        display: flex;
        align-items: center;
        &__translation {
            display: flex;
            margin-top: 5px;
        }
        &__label {
            width: 300px;
            label { margin: 0px; }
            @media (max-width: $desktopBreakPoint) {
                width: 100%;
            }
        }
        &__interaction {
            width: calc(100% - 300px);
            @media (max-width: $desktopBreakPoint) {
                width: 100%;
            }
            select {
                width: 100%;
            }
            input {
                width: 100%;
            }
            textarea {
                width: 100%;
                border-width: 0 0 1px 0;
            }
            &__shortTextbox {
                width: 50%;
            }
        }
    }
    &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: white;
        width: calc(100% - 30px);
        margin: 20px 20px 40px 20px;
        padding: 35px 10px 10px 10px;
        box-shadow: 0 4px 8px 0 RGB(0 0 0 / 20%);
    }

    &__headerLabel {
        position: absolute;
        background-color: white;
        top: -20px;
        left: -10px;
        font-weight: 700;
        margin: 2px 0;
        border: 1px solid gainsboro;
        border-radius: 10px;
        padding: 5px 10px;
        width: 300px;
        @media (max-width: $desktopBreakPoint) {
            width: 100%;
        }
    }
}
