@import "variables";

.projectSettings {
    &__name {
        display: flex;
        align-items: center;
    }

    &__id {
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__header {
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 30px;
        background-color: whitesmoke;
        border: 1px solid gainsboro;
        box-shadow: 0 4px 8px 0 RGB(0 0 0 / 20%);
        margin: 0px 0 15px 0;
        width: 100%;
        @media (max-width: $desktopBreakPoint) {
            flex-direction: column;
            height: auto;
            background-color: transparent;
            border: 0;
            box-shadow: 0 0 0 0 RGB(0 0 0 / 20%);
        }
        &__interaction {
            display: flex;
            height: 100%;
            align-items: center;
            @media (max-width: $desktopBreakPoint) {
                flex-direction: column;
                width: 100%;
            }
        }

        &__newItem {
            display: flex;
            align-items: center;
            padding: 0 20px;
            background-color: gainsboro;
            color: $blueColor;
            svg-icon { margin-right: 20px; fill: $blueColor; }
            height: 100%;
            min-width: 200px;
            i { margin-right: 5px; }
            @media (max-width: $desktopBreakPoint) {
                width: 100%;
                box-shadow: 0 4px 8px 0 RGB(0 0 0 / 20%);
                background-color: whitesmoke;
                height: 45px;
                border: 1px solid gainsboro;
                margin: 10px;
            }
        }

        &__button {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 20px;
            background-color: gainsboro;
            color: $blueColor;
            margin: 0 5px;
            input { height: 23px; width: 100%; }
            select { color: white; height: 23px; width:100%; }
            svg-icon { margin-right: 20px; fill: $blueColor; }
            i { margin: 0 10px; color: $blueColor; }
            @media (max-width: $desktopBreakPoint) { width: 100%; }
        }

        &__filter {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 20px;
            background-color: gainsboro;
            color: $blueColor;
            input { height: 23px; width: 100% !important; }
            svg-icon { margin-right: 20px; fill: $blueColor; }
            @media (max-width: $desktopBreakPoint) {
                width: 100%;
                padding: 2px;
                order: 1;
                box-shadow: 0 4px 8px 0 RGB(0 0 0 / 20%);
                background-color: whitesmoke;
                svg-icon { margin-right: 5px; fill: $blueColor; }
            }
        }

        &__showOnlyActive {
            display: flex;
            align-items: center;
            padding: 0 20px;
            color: $blueColor;
            input {
                margin-right: 10px;
                width: 15px;
                @media (max-width: $desktopBreakPoint) {
                }
            }
            &__filter {
                width: 200px !important;
            }
            @media (max-width: $desktopBreakPoint) {
                width: 100%;
                order: 2;
                margin-top: 10px;
                order: 2;
                display: block;
            }
        }
    }

    &__wrapper {
        display: flex;
        height: 100%;
        align-items: flex-start;
        width: 100%;
        overflow-y: auto;
    }

    &__inputFull {
        width: 100%;
    }

    &__inputMin {
        min-width: 300px;
        @media (max-width: $desktopBreakPoint) {
            min-width: 200px;
        }
    }

    &__isInactive {
        background-color: gainsboro !important;
        color: black;
        border-bottom: 2px solid whitesmoke;
        i {
            color: black;
        }
        label {
            color: black;
        }
    }

    &__newRow {
        background-color: $greenColor !important;
        i {
            color: white;
        }
        label {
            color: white;
        }
    }

    &__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-right: 35px;
        min-width: 400px;
        max-width: 400px;
        i {
            margin-right: 5px;
        }

        &__result {
            padding-left: 20px;
            width: 100%;
            &__id {
                width: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 50px;
                max-width: 50px;
            }
        }
    }

    &__leateStateDesc {
        width: calc(100% - 10px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 10px;
        max-width: 350px;
        padding-left: 5px;
    }

    &__resultName {
        min-width: 290px;
        width: 100%;
        height: 26px;
    }

    &__isActive {
        color: $secondary !important;
    }

    &__nextStep {
        display: flex;
        min-width: 400px;
    }

    &__section {
        display: flex;
    }

    &__dropdown {
        background-color: white;
        color: $secondary;
        margin: 0 2px;
        min-width:500px;
        
    }

    &__infoRowStep {
        background-color: RGB(235,235,235) !important;
        color: $blueColor;
        font-weight: 700;
        i {
            color: $blueColor;
        }
        label {
            color: $blueColor;
        }
    }

    &__infoRowWorkflow {
        background-color: silver !important;
        i {
            color: $blueColor;
        }
        label {
            color: $blueColor !important;
        }
    }
}
