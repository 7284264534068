/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import "variables";
@import '../src/app/jollyjupiter/style/everleadShared';
@import './assets/styles/company.color.scss';
@import './assets/styles/snow.scss';
@import './assets/styles/jollyJupiter.var.scss';
@import '../src/app/jollyjupiter/style/dragdrop.scss';
@import '../src/app/jollyjupiter/style/customTextControlWrapper.scss';
@import '../src/app/jollyjupiter/style/ajTracker.scss';
@import '../src/app/jollyjupiter/controls/static/wizard/wizard.scss';
@import '../src/app/jollyjupiter/style/nonExpertView.scss';
@import '../src/app/jollyjupiter/style/_nonExpertViewMatTable.scss';
@import '../src/app/jollyjupiter/style/uiControlEditor.scss';
@import '../src/app/jollyjupiter/style/incomingcall.scss';
@import '../src/app/jollyjupiter/style/fireworks.scss';
@import '../src/app/jollyjupiter/style/customcontrols.scss';
@import '../src/app/jollyjupiter/style/dataFilter.scss';
@import '../src/app/jollyjupiter/style/documentation.scss';
@import '../src/app/jollyjupiter/style/usermanagement.scss';
@import '../src/app/jollyjupiter/style/statuscolor-default.scss';
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import './assets/styles/controls.scss';
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import '../src/app/jollyjupiter/style/newCustomMatTable.scss';
@import '../src/app/jollyjupiter/style/component-layout.scss';
@import '../src/app/jollyjupiter/style/tabsMobile.scss';
@import '../src/app/jollyjupiter/style/defaultDashboards';

//@import './app/jollyjupiter/controls/custom/custom-mattable/mat-table-responsive.directive';
@import '../src/app/jollyjupiter/style/projectSettings.scss';

@keyframes ui-progress-spinner-color {

    100%,
    0% {
        stroke: #d62d20;
    }

    40% {
        stroke: #0057e7;
    }

    66% {
        stroke: #008744;
    }

    80%,
    90% {
        stroke: #ffa700;
    }
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

// ! ivo: tippy.js and ngx-tippy-wrapper
@import 'tippy.js/themes/light.css';
@import 'tippy.js/themes/light-border.css';
// !

// @import '@angular/material/theming';
$custom-typography: mat.define-typography-config($font-family: '"Poppins"');
$custom-theme: mat.define-light-theme((
  typography: $custom-typography,
));
@include mat.all-component-themes($custom-theme);


input[type=text],
input[type=button],
input[type=submit],
button,
[type="button"],
[type="reset"],
[type="submit"] {
    border-radius: 0;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
}


   body{
    overscroll-behavior: none;
    touch-action:manipulation; 
    // ! test overscroll on devices 13.02.2025;
  }


input {
    border-width: 0 0 1px 0;
    outline: 0;
    background-color: white;
    color:$fontDark !important;
    //color: $blueColor;
}

textarea {
    //color: $blueColor;
    color: $fontDark !important;
}

input:focus {
    color:$fontDark !important;
    //color: $secondary;
}

body,
label,
input,
textarea,
select,
option {
    font-family: 'Poppins';
}



h1{
    font-size: $headerH1Size;
    line-height: $headerH1LineHeight;
    font-weight: 600;
    margin:0;
    padding:0;
}

h2{
    font-size: $headerH2Size;
    line-height: $headerH2LineHeight;
    font-weight: 600;
    margin:0;
    padding:0;
}

.mobile-visible {
  display: none;
}

.desktop-visible {
  display: block;
}
@media (max-width: $desktopBreakPoint) {
  .mobile-visible {
    display: block;
  }
  .desktop-visible {
    display: none;
  }
}


.loader{
    color:$secondary;
    font-size: 12px;
}

.noDataAvailable {
    padding: 24px;
    color:$secondary;
    font-size: 12px;
}


.defaultDashboardAccordionMobile{
    width:100%;

    @media(min-width:$tabletBreakPoint){
        display:none
    }
}

.defaultDashboardAccordionNotMobile{
    display:none;
    @media(min-width:$tabletBreakPoint){
        display:block;
        width:100%;
        border-bottom:$globalBorder;
    }
}

.tippy-content {
    line-break: normal !important;   
}

.tippy-root {
    z-index: 999999999 !important;    
}

.data-tippy-root {
    z-index: 999999999 !important;
}

.tippy-box {
    color: white;
    padding:0!important;
    max-width: 250px;  
    overflow: hidden;  
    word-wrap: break-word;
    z-index: 999999999 !important;
}

.tippy-arrow {
    border-top: none;
}

.tippy-content:empty {
    display: none;
}

.tippy-content:empty+.tippy-arrow {
    display: none;
    word-break: normal;
}

.tippy-box[data-theme~=everleadRed] {
    background-color: #333333;
    color: white;
    word-break: normal;
    .tippy-arrow {
        color: #333333;
    }
}

.tippy-box[data-theme~=everleadNav] {
    background-color: #333333;
    color: white;
    word-break: normal;
    .tippy-arrow {
        color: transparent;
    }
}

.tippy-box[data-theme~=everleadBlue] {
    background-color: #333333;;
    color: white;
    word-break: normal;
    .tippy-arrow {
        color: #333333;
    }
}

.tippy-box[data-theme~=callHistory]{
    background-color: #333333;
    color:white;
    word-break: normal;
    .tippy-arrow{
        color:#333333;
    }
}

.tippy-box[data-theme~=light-border]{
    background-color: $primaryLight;
    width: 100%;
    max-width: 450px !important;

    .tippy-content {
        line-break: anywhere;
        width: 100%;
        text-align: left;
        background-color: $primaryLight;
    }
}

/* .tippy-box[data-theme~=light-red-border] {
    background-color: white;
    color: $coralColor;
    box-shadow: 0px 2px 4px 0px rgba(224, 12, 12, 0.1);
    border: 1px solid red;
    border-radius: 5px;
    word-break: normal;
    .tippy-content {
        line-break: anywhere;
        width: 100%;
        text-align: left;
        color: $coralColor;
        border-radius: 5px;
    }

    .tippy-arrow {
        border-color: red;
    }


    .tippy-arrow::before {
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23FFF'%3E%3Cpath d='M10 0l10 20H0l10-20z'/%3E%3C/svg%3E");
        mask-size: cover;
        background-color: transparent;
    }
} */

.tippy-box[data-theme~=everleadChartTooltip] {
    background-color: #333333;
    color: white;
    .tippy-arrow {
        color: #333333;
    }

    .tippy-content {
        text-align: left;
        color:  $blueColor;
        border-radius: 5px;
    }
}

.tippy-box[data-theme~=everleadBlack] {
    background-color: #333333;
    color: white;
    word-break: normal;
    .tippy-arrow {
        color: #333333;
    }
}

.tippy-box[data-theme~=everleadLightgrey] {
    background-color: #333333;
    color: white;
    word-break: normal;
    .tippy-arrow {
        color: #333333;
    }
}

.tippy-box[data-theme~=ever-customTimeline] {
    background-color: $blueColor;
    color: white;

}



.tippy-box[data-theme~=everlead-selector]{
    background-color: $secondaryShade5 !important;
    width: 100% !important;
    max-width:98% !important;
    overflow-y:auto !important;
    max-height:inherit!important;
    border-radius:$globalBorderRadius !important;

    .tippy-content {
        line-break: anywhere;
        width: 100%;
        text-align: left;
    }
}

.tippyIconSelector{
    width:100%;
    overflow-y: auto;
    color:$fontDark;
    width:100%;


    &__value{
        width:100%;
        color:$fontBlack;
        display:flex;
        align-items: center;
        background-color: $secondaryShade5;
        padding:0.5rem 0;
        cursor:pointer;

        label{
            margin:0;
            padding:0;
            padding-left:0.5rem;
        }

        &.isSelected{
            background-color: $secondaryShade4;
        }

        &:hover{
            background-color: $secondaryShade4;
            color:$fontDark;
        }
    }

}


.tippy-box[data-theme~=everlead-select]{
    background-color: $secondaryShade5 !important;
    width: 100% !important;
    max-width:98% !important;
    //overflow-y:auto !important;
    max-height:inherit!important;
    border-radius:$globalBorderRadius !important;
    border:$globalBorder!important;
    scrollbar-width: none;
    -ms-overflow-style:none;

    &::-webkit-scrollbar{
        display:none;
    }

    .tippy-content {
        line-break: anywhere;
        width: 100%;
        text-align: left;
        padding:0px 0px !important;
    }
}

.selectTippyOption{
    width:100%;
    overflow-y: auto;
    color:$fontDark;
    scrollbar-width: none;
    -ms-overflow-style:none;

    &__outer{
        width:100%;
        display:flex;
        flex-direction: column;
        align-items: center;
    }

    &::-webkit-scrollbar{
        display:none;
    }


    &__option{
        width:100%;
        color:$fontBlack;
        display:flex;
        height:30px;
        align-items: center;
        background-color: $secondaryShade5;
        padding:0.5rem;
        cursor:pointer;
        font-size:14px;

        app-jj-icons{
            margin-right:0.2rem;
        }

        &.isSelected{
            background-color: $secondaryShade4;
        }

        &:hover{
            background-color: $secondaryShade4;
            color:$fontDark;
        }
        &.highlighted{
            background-color: $secondaryShade4;
        }
    }

}

// ! TOOLTIP ON FLOW CHART

.tooltipFlowChart {
    font-family: 'Poppins';
    width: 12rem;


    &__svgwrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        white-space: normal !important;
        border: 1px solid rgb(172, 171, 171);
        border-radius: 5px;
        margin:0;
        padding:0;
        margin-bottom:0;

        svg{
            width:20px;
            white-space: normal !important;
            fill: $secondary;
        }

        p {
            color: $fontDark;
            font-size: 0.7rem;
            white-space: normal !important;
            margin-bottom: 0;
        }
    }

    &__status{
        font-size:0.7rem;
        margin:0.2rem 0;
        white-space: normal !important;
        margin-bottom: 0;

        p {
            color: $fontDark;
            margin-bottom: 0;
            white-space: normal !important;
        }
    }

    &__section {
        margin: 0.6rem 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        overflow: hidden;

        h4 {
            font-size: 0.7rem;
            color: black;
            font-weight: bold;
            margin: 0.2rem 0;
        }

        span {
            border: 1px solid rgb(202, 201, 201);
        }

        p {
            font-size: 0.7rem;
            margin: 0.2rem 0;
            white-space: normal !important;
            word-break: break-all;
        }

        span {
            border: 1px solid rgb(202, 201, 201);
        }

    }


}

.tooltipLeadscoring{

    &__header{
        border-bottom: 1px solid $secondary;
        display: flex;
        align-items: center;
        padding:0.5rem 0;

        svg{
            fill: $secondary;
            width:25px;
            height:25px;
        }

        p{
            margin:0;
            padding:0;
            padding-left: 1rem !important;
            color: $secondary;
            font-weight: 600;
            font-size: 14px;
        }
    }

    &__content{

        padding:0.5rem 0;

        span{
            padding:0.5rem 0;
            width:100%;
            font-size: 12px;

            p{
                margin: 0;
                padding:0;
                color:$fontDark;
            }

            a{
                color: $secondaryShade1;
                padding:0;
                margin:0;
                cursor:'pointer';
                text-decoration:none;

            }
        }
    }

    &__footerHeader{
        border-bottom:1px solid $secondary;
        color:$secondary;
        text-wrap: wrap;
        
        &__header{
            padding-bottom:0.2rem;
            display:flex;
            align-items: flex-end;

            span{
                font-size: 14px;
                font-weight: 600;
            }

            svg{
                width:20px;
                height:20px;
                fill:$secondary;
                margin-left:0.2rem;
                font-weight: 400;
                cursor:pointer;
            }
        }

    /*     &__info{
            font-size:10px;
            text-wrap: wrap;
            width:320px;
        } */

    }

    &__footer{
        padding:1rem 0;
        display:flex;
        justify-content: space-around;

        span{
            display:flex;
            flex-direction: column;
            align-items: center;

            p{
                margin: 0;
                padding:0;
                font-size:2rem;
                color:$secondary;
            }

            label{
                color:$fontDark;
            }

            .tippylabel{
                color:$secondary;
                display:flex;
                align-items: center;
                cursor:pointer;

                svg{
                    width:18px;
                    height:18px;
                    fill:$secondary;
                    margin-left:0.2rem;
                }
            }
        }
    }

    &__info{
        width:100px;
        max-width:100px;
        display:flex;
        flex-direction: column;
        text-wrap: wrap;
    }

}

/* .tooltipLeadscoringDetail{


    &__header{
        border-bottom: 1px solid $secondary;
        display: flex;
        align-items: center;
        padding:0.5rem 0;

        svg{
            fill: $secondary;
            width:25px;
            height:25px;
        }

        p{
            margin:0;
            padding:0;
            padding-left: 1rem !important;
            color: $secondary;
            font-weight: 600;
            font-size: 14px;
        }
    }

    &__content{

        padding:0.5rem 0;

        span{
            padding:0.5rem 0;
            width:100%;
            font-size: 12px;

            p{
                margin: 0;
                padding:0;
                color:$fontDark;
            }

            a{
                color: $secondaryShade1;
                padding:0;
                margin:0;
                cursor:'pointer';
                text-decoration:none;

            }
        }
    }

    &__footerHeader{
        width:100%;
        border-bottom:1px solid $secondary;
        color:$secondary;
        text-wrap: wrap;
        
        &__header{
            font-size: 16px;
            font-weight: 600;

            svg{
                width:20px;
                height:20px;
                fill:$secondary !important;
                margin-left:0.3rem;
            }
        }

        &__info{
            font-size:12px;
        }

    }

    &__footer{
        padding:1rem 0;
        display:flex;
        justify-content: space-around;

        span{
            display:flex;
            flex-direction: column;
            align-items: center;

            p{
                margin: 0;
                padding:0;
                font-size:2rem;
                color:$secondary;
            }

            label{
                color:$fontDark;
            }
        }
    }

    &__info{
        width:100px;
        max-width:100px;
        display:flex;
        flex-direction: column;
        text-wrap: wrap;
    }

    &__asterisk{
    }
} */
 



.tooltipProspectsDashboard{
    display:flex;
    flex-direction: column;

    &__header{
        display:flex;
        align-items: flex-end;
        color:$secondary;
        font-size: 16px;
        width:100%;
        padding:0.5rem 0;
        border-bottom: $globalBorder;
        font-weight: 600;
        
        svg{
            fill:$secondary;
            width:20px;
            height:20px;
            margin-right:0.5rem;
        }
        p{
            //font-size: 16px;
            margin:0;
            padding:0;
        }

        span{
            //font-size: 16px;
            color:$secondary;
        }
    }

    &__content{
        padding:0.5rem 0;
        width:100%;
        display:flex;
        flex-direction:column;
        max-width:250px;
      
    }

    &__label{
        display:flex;
        flex-direction: column;
        text-wrap:wrap;
        font-size:12px;
        font-weight:600;
        overflow:hidden;
        white-space: normal !important;
        max-width:450px;
        strong {
            color:$secondary;
        }

        p{
            color:$fontBlack;
            white-space: normal !important;
            text-wrap: wrap;
            word-break: break-all;
        }

        a{
            cursor:pointer;
            color:$fontBlack;
            text-wrap: wrap;
            word-break: break-all;
        }

 
    }

    &__duplicates{
        padding:0.1rem;
        width:100%;
        overflow: auto;
        max-height:200px;
        white-space: normal !important;
        

            &__row{
                display:flex;
                flex-direction: column;
                width:100%;
                white-space:normal !important;
                border-bottom: 1px solid $secondaryShade4;
                padding:0.5rem 0;
            }

            &__label{
                display:flex;
                flex-direction:column;
                font-size:12px;
                width:100%;
                overflow: hidden;
                text-wrap: wrap;
                white-space: normal;
                max-width:350px;
                font-weight:600;

            /*     svg{
                    width:20px;
                    height:20px;
                    fill:$secondary;
                } */

                strong{
                    color:$secondary;
                    svg{
                        width:20px;
                        height:20px;
                        fill:$secondary;
                    }
                }

                p{
                    color:$fontBlack;
                    overflow:hidden;
                    white-space: normal !important;

                    strong{
                        color:$secondary;
                        margin-right:0.5rem;
                        white-space:normal;
                    }

                    a{
                        cursor:pointer;
                        color:$fontBlack;
                        text-wrap: wrap;
                        word-break: break-all;  
                    }
                }
            }
    }

}




.top.dotted-space {
    background-image: linear-gradient(to right, #333 10%, rgba(255, 255, 255, 0) 0%);
    background-position: top;
    background-size: 10px 1px;
    background-repeat: repeat-x;
}

.linear-bg {
    &--blue {
        background: RGB(82, 144, 248) !important;
        background: linear-gradient(90deg, #8CB5FB 0%, rgba(24, 107, 246, 1) 100%);
    }

    &--yellow {
        background: RGB(248,186,82);
         background: linear-gradient(90deg,  #F9C66F  0%, rgba(246,163,24,1) 100%);
    }

    &--green {
        background: RGB(123, 206, 157) !important;
    }

    &--maracuja {
        background: rgba(246, 163, 24, 1) !important;
        background: linear-gradient(90deg, rgba(249, 198, 111, 1) 0%, rgba(246, 163, 24, 1) 100%);
    }

    &--pink {
        background: #CE7BAC !important;
        background: linear-gradient(90deg, #CE7BAC 0%, #BD4F90 100%),
    }

    &--red {
        background:  #F18B8B  !important;
        background: linear-gradient(90deg, #F18B8B  0%, #EC6464 100%);

    }

    &--purple {
        background: #7C76B1 !important;
        background: linear-gradient(90deg, #7C76B1 0%, #57518E 100%);
    }
}

.no-border {
    border: none;
}

.chart-box {
    padding: 10px 20px;
    border: none;
    box-shadow: 0px 1px 2px 0px #3e3e3e;
    border-radius: 2px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    text-align: center;

    .caption {
        color: #fff;
        text-align: left;

        .final-value {
            font-size: 36px;
        }

        .text {
            font-size: 14px;
            text-transform: uppercase;
        }
    }
}

.text-black {
    color: #000;
}

.text-center {
    text-align: center;
}

.flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
}

.nopadding {
    padding: 0;
}

body {
    .p-component-overlay {
        z-index: 999998 !important;
    }
    
    .p-confirm-dialog.p-dialog  {
        border-radius: 4px;
        filter: drop-shadow(0px 1px rgba(0, 0, 0, 0.5));
        z-index: 999999 !important;
        width: 30em;

        .p-dialog-header {
            height: 65px;
            display: table;
            width: 100%;

            .p-dialog-title {
                font-family: 'Poppins';
                font-size: 26px;
                font-weight: 400;
                text-transform: uppercase;
                display: table-cell;
                text-align: left;
                vertical-align: bottom;
                width: 100%;
                height: 100%;
            }

            .p-dialog-header-icon {
                background-image: url('assets/images/svg/close.svg');
                background-repeat: no-repeat;
                background-size: contain;
                width: 24px;
                height: 24px;
                position: absolute;
                right: 25px;
                top: 22px;

                .p-icon-wrapper {
                    display: none;
                }
            }
        }

        .p-dialog-content {
            display: flex;
            justify-content: center;
            align-items: center;

            .p-confirm-dialog-icon {
                font-size: 48px;
                display: block;
                top: unset;
            }
        }        

        .p-dialog-footer {
            padding: 15px;

            button {
                min-width: 100px;
                margin: 0 5px;
                padding: .429em 1em;
                border-radius: 4px;
                text-transform: uppercase;
                font-size: 18px;

                &:not(:hover) {
                    background-color: $blueColor;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}


// ! not included in body
.mat-mdc-header-cell {
    button {
        font-size: 18px;
        text-transform: uppercase;
    }
}

.jjButtonColor {
    background-color: $buttonColorDark;
}

.circle-icon {
    font-size: 26px;
    background: $blueColor;
    color: #fff;
    display: table;
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;

    &:before {
        display: table-cell;
        vertical-align: middle;
    }
}


.icon-text {
    display: flex;
    align-items: center;

    span {
        padding-right: 15px;
    }
}

.icon-event {
    &:before {
        font-size: 18px;
        content: "\f245";
    }

    &__Open:before {
        content: "\f658"
    }

    &__Send:before {
        content: "\f1d8"
    }

    &__Click:before {
        content: "\f8cc"
    }
    &__Delivery:before {
        content: "\f0d1"
    }

    &__formSubmisson:before {
        content: "\f56c"
    }

    &__pageView:before {
        content: "\f06e"
    }

    &__conversionwebinar-form:before {
        content: "\f15c"
    }
}



.tableFilter {
    font-size: 16px;
    color:#2C2C2C;

    &.border-bottom {
        border-bottom: solid 1px $border-color;
    }

    &__category {
        padding: 15px 0;
        @media (max-width: $tabletBreakPoint) {
            display: flex;
            width: 100%;
            justify-content: space-between;
            padding: 5px 0;
        }

        display: inline-block;
        font-size: 17px;
        text-transform: uppercase;
        margin-right: 25px;
        color: gray;
        cursor: pointer;
        transition: all 0.1s ease 0s;

        &:hover {
            color: #9D9FA5 !important;

        }


        &.active {
            color: #000;

           .tableFilter__category__count {
                background: $blueColor;
                color: #fff;
                border-color: $blueColor;
            }
        }

        &__count {
            display: inline-block;
            min-width: 35px;
            border: solid 1px lightgray;
            border-radius: 9px;
            text-align: center;
            margin-left: 10px;

        }
    }
}


.status-badge {
    border-radius: 4px;
    background-color: gray;
    text-transform: uppercase;
    color: #fff;
    font-weight: 300;
    padding: 7px 5px;
    font-size: 18px;
    width: fit-content;

    &.positive {
        background: #7BCE9D;
    }

    &.negative {
        background: #EC6464;
    }

    &.neutral {
        background: #F6A318;
    }
}

.status-badge-micro-campaign {
    border-radius: 4px;
    background-color: gray;
    text-transform: uppercase;
    color: #fff;
    font-weight: 300;
    padding: 4px 5px;
    height: 30px;
    font-size: 18px;
    width: fit-content;

    &.positive {
        background: #7BCE9D;
    }

    &.negative {
        background: #EC6464;
    }

    &.neutral {
        background: #F6A318;
    }
}

.mat-mdc-paginator {
    background-color: transparent !important;
}

@media only screen and (max-width: $desktopBreakPoint) {
    .paginationWrapper  {
        align-self: start;
        width: 100%;
        margin-top: 30px;

        .mat-mdc-paginator-outer-container {
            .mat-mdc-paginator-container {
                justify-content: space-between;
            }

            .mat-mdc-paginator-range-actions {
                display: block;
            }

            .bodyNewDesign button,
            .mat-mdc-icon-button {
                width: unset;
            }
        }
    }


    .mat-mdc-form-field+svg-icon {
        margin-left: -25px !important;
    }


    .desktopVisible {
        display: none;
    }

    .desktopHidden {
        display: block;
    }

    .bodyNewDesign {

        .leadStateHeaderDiv {
            height: unset;
        }

        #routerHeaderDiv {
            position: relative;

            .element-detail {
                padding: 0 25px 25px 25px;
            }
            .tableFilter {
                padding: 20px;
                &__category {
                    display: flex;
                    justify-content: space-between;
                    margin: 0 0 15px 0;
                }
            }
        }
        .mat-mdc-form-field-label-wrapper {
            position: relative !important;
        }

        .desktopVisible {
            display: none;
        }

        .desktopHidden {
            display: block;
        }

        jj-universal  {
            display: block;

            .universalComponent {
                display: block;
                width: 100% !important;
                padding: 0 !important;
            }
        }

        input,
        select {
            // margin: 0 auto;
        }

        .jjTab{
            margin-bottom:0 !important;
            border-bottom: 0 !important;
        }

        .jjTab button,

         button
            :not([class*="mat-paginator"])
            :not([class*="p-carousel"]),
        //  button.p-button,
         button.jjButtonStyle {
            width: 100%;
            margin: 5px auto;
            border:1px  solid red;
        }

        .checkBoxContainer {
            margin: 15px 0;

        }

        input[type="radio"],
        input[type="checkbox"],
        button.jjControlSubmenuButton {
            width: unset;
        }

        h2,
        .matTableHeaderLabel {
            font-size: 1.2rem !important;
        }

        .softPhoneWrapper {
            top: 80px;
            position: fixed;
            right: 0;
            width: initial !important;
            z-index: 100;
            display: flex;
            justify-content: center;
            align-items: stretch;

            &__arrow {
                border-left: solid 1px #FFFFFF;

                &.desktopHidden {
                    display: block;
                }
            }
        }
    }
}
@media only screen and (max-width: 380px) {
    input,
    select
     {
      width: 100%;
     }
    .paginationWrapper  {
        .mat-mdc-paginator-outer-container{
            .mat-mdc-paginator-container {
                justify-content: center;
            }
        }
    }
}


.desktopVisible {
    display: block;
}

.desktopHidden {
    display: none;
}

.e-dbrd-control-error.align-center.e-container-dbrd-theme {
    z-index: 1 !important;
}


.form-field-with-icon {
    position: relative;

    mat-form-field {
        width: 100%;
        font-size: 16px;

        input {
            padding-right: 55px;
        }
    }

    .icon-wrapper {
        position: absolute;
        right: 0;
        bottom: 5px;
    }
}
@media only screen and (max-width: 1200px) {
   .tabletInvisible {
       display: none;
   }
}

select {
    background-color: transparent;
}

select:disabled {
    appearance: none;
    border-bottom: 0 !important;
}


.row,
.col-md-6,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-md-5,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12 {
    margin: 0px;
    padding: 0 5px;
}
.col{

    &__nopadding{
        padding:0 !important;
    }
}

table {
    background-color: transparent;
}

.controlInlineBlock {
    position: relative;
    height: 100%;
    width: 100%;
}

.maxWidth {
    width: 100%;
}

.bold {
    font-weight: 700;
}

.uppercase {
    text-transform: uppercase;
}

.mat-mdc-table {
    background-color: transparent !important;
}

.searchBox {
    background-color: #F4F4F5;
    border-radius: 2px;
    ;
    filter: drop-shadow(0px 2px rgba(0, 0, 0, 0.25));
    margin: 10px 0;
    position: relative;
    width: 100%;
    display: flex;

    &__mobile {
        width: 100%;
    }

    &:hover {
        background-color: #EAEAEB;
    }

    flex-direction: row;

    &__search {
        background-color: white;
        cursor: pointer;
        width: calc(100% - 40px);
        height: 24px;
        border-width: 0;
        outline: none;
        font-family: 'Poppins';
        padding: 10px;
        z-index: 1;
        margin: 0 !important;
        text-align: center;
        margin-left: 10px !important;
        border-radius: 5px !important;
        /* border: 1px solid gainsboro; */
        padding-right: 40px;
    }

    &__searchButton {
        background-color: transparent;
        color: black;
        border: 0;
        margin: 0 !important;
        height: inherit;
        padding-right: 15px;
        z-index: 2;
        flex: 1;
    }

    &__wrapper {
        display: flex;
        align-items: center;
    }

    &__container {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        align-content: space-between;
        border-radius: 5px;
        padding: 0 6px;
        border: 1px solid gainsboro;
        background-color: white;
    }
}


.everleadShadow{
    box-shadow: 0 4px 8px 0 RGB(0 0 0 / 20%);
}

.everleadShadowBox {
    background-color: white;
    padding: 10px;
    margin: 0 0px 10px 0;
    box-shadow: 0 4px 8px 0 RGB(0 0 0 / 20%);
    width: calc(100% - 4px);
    border: 1px solid whitesmoke;

    label {
        margin: 0;
    }
}

.everleadSmallShadowBox {
    background-color: white;
    padding: 3px;
    margin: 0 0px 3px 0;
    box-shadow: 0 4px 8px 0 RGB(0 0 0 / 20%);
    width: calc(100% - 4px);
    font-size: small;

    label {
        margin: 0;
    }
}

.everleadFlexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    label { margin: 0; }
}

.everleadHorizontalFlex {
    display: flex;
    align-items: center;
    label { margin: 0; }
  }

  .isDeveloper {
    background-color: $secondaryShade5 !important;
    padding: 4px;
    border: 1px solid $secondaryShade5;
    border-radius: $globalBorderRadius;
}

.nudeButton {
    border: 0;
    outline: 0;
    background-color: transparent;
}

.autoMarginLeft {
    margin-left: auto;
}

.bottomBar {
    display: flex;
    align-items: center;
    margin-top: 5px;
    color: gray;

    i {
        margin-right: 5px;
    }

    svg-icon {
        fill: gray;
        margin-right: 5px;
    }

    border-top: 1px dashed gray;
    font-size: small;
}

.fontSmall {
    font-size: small;
}

.backgroundGradient {
    background: linear-gradient(
        190deg,
        rgba(225,225,231,1) 0%,
        rgba(230,230,230,1) 35%,
        rgba(212,213,220,1) 72%,
        rgba(218,222,228,1) 81%,
        rgba(236,232,234,1) 100%
    );
}

.whiteSpaceInitial {
    white-space: initial;
}

.flexCenter {
    display: flex;
    align-items: center;
}

.flexCenterIcons {
    display: flex;
    align-items: center;

    i {
        margin: 0 5px;
    }

    svg-icon {
        margin: 0 5px;
    }
}

.everleadLocalPopup {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid gainsboro;
    background-color: white;
    z-index: 10;

    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: whitesmoke;
        padding: 10px 20px;
        height: 45px;
        label { margin: 0; }
    }
    &__background {
        z-index: 5;
        left: 0;
        width: 100%;
        top: 0;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        position: fixed;
    }
}

.everleadSmallHeader {
    font-size: small;
    color: gray;
}

.noMargin {
    margin: 0px;
}

.flex-justify-flexend {
    justify-content: flex-end;
}

.smallerFontSize {
    font-size: smaller;
}

.everleadMaxHeightOverflow {
    height: 100%;
    overflow-y: auto;
}

.everleadContentContainer {
    position: relative;
}

.maxHeightImportant {
    height: 100% !important;
}

.everleadShadowBox2 {
    border: 1px solid rgba(240, 230, 240, 0.9);
    box-shadow: 0 3px 5px 0 RGB(0 0 0/20%);
}

.everleadMediumFontSize {
    font-size: 16px;
}

.everleadSmallFontSize {
    font-size: 12px;
}


.iconForTooltip {
    margin: auto;
    min-width: 20px;
    margin: 0 10px 0 20px;
    &__message {
        font-size: 12px;
        white-space: normal;
        color: gray;
        padding: 10px;
    }
}

.radioButtonSelector {
    display: flex;
    margin: 5px 0;
    //border-bottom: 1px dotted white;
    padding-bottom: 5px;
    justify-content: space-between;
    &__buttons {
        display: flex;
        align-items: center;
    }
    &__button {
        margin: 0 10px;
        color: white;
        input { margin: 0 10px;}
    }
    &__new {
        min-width: 200px;
        display: flex;
        align-items: center;
        color: white;
        cursor: pointer;
        i { margin: 0 10px; }
    }
}

.agComponentHeader {
    &__wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 8px;
        background-color: white;
        white-space: nowrap;
        // flex-wrap: wrap;

        @media(max-width:$tabletBreakPoint){
            display: flex;
            flex-direction:column;
            align-items: flex-start;
            justify-content: left;
        }
    }
    &__header {
        min-width: 350px;
    }
    &__menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-top: 1rem;
        margin-right:0.5rem;
        justify-content: flex-end;    
        
        @media(max-width:$tabletBreakPoint){
            display: flex;
            flex-direction:column;
            align-items: flex-start;
            justify-content: left;
            padding:0.5rem 0.3rem;
            margin-right:0;
            margin-top:0;
            
            app-filter{
                padding:0.5rem;
                width:100%;
            }
        }
    }

    &__item {
        padding: 0 4px;
        margin: 0 4px;        
        display: flex;
        align-items: center;
        gap: 4px;
    }
}

.agEverleadBorder {
    border: 1px solid #EDEDED;
    border-radius: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
}

.agEverleadBorderInner {
    border: 1px solid #EDEDED;
    border-radius: 8px;
    background-color: white;
    padding: 16px; 
    margin: 16px;
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.everleadPadding{
    padding:0.5rem;
}

.containerBorderStyle01, .containerBorderStyle02 {
    border: 0 !important;
    padding: 0;
    margin: 0;
}

.oddRow {
    background-color: $primaryDark;
}

.everleadTableRow {
    background-color: $primaryLight;
}
.everleadTableRow:nth-child(odd){
    background-color: $primaryDark;
}
.everleadTableRow:hover {
    background-color: $secondaryShade5;
}

.everleadCapitalizeValue {
    text-transform: capitalize;
}

.cleanMessageItem {
    display: flex;
    align-items: center;
    &__key {
        min-width: 200px;
        
    }    
    &__value {
        width: 100%;
    }
}