:root {
  --primary-color-skyblue-dark: RGB(0, 107, 179);
  --primary-color-skyblue-light: RGB(194, 229, 241);
  --primary-color-black: RGB(0, 0, 0);
  --primary-color-white: RGB(255, 255, 255);

  --secondary-color-nightblue: RGB(28, 42, 48);
  --secondary-color-darkslateblue: RGB(4, 59, 93);
  --secondary-color-midnightblue: RGB(49, 70, 128);
  --secondary-color-seagreen-dark: RGB(0, 109, 126);
  --secondary-color-seagreen-medium: RGB(0, 158, 127);
  --secondary-color-limegreen: RGB(149, 193, 84);
  --secondary-color-grey-green-medium: RGB(192, 209, 195);
  --secondary-color-grey-green-hell: RGB(237, 243, 217);
  --secondary-color-grey-bright: RGB(236, 242, 245);
  --secondary-color-sand: RGB(218, 215, 208);
  --secondary-color-wheat: RGB(227, 215, 176);
  --secondary-color-crimson: RGB(203, 0, 65);
  --secondary-color-rose-dark: RGB(204, 128, 176);
  --secondary-color-goldenyellow: RGB(250, 187, 0);
  --secondary-color-lemon-yellow: RGB(255, 230, 8);
  --secondary-color-lemonchiffon: RGB(255, 246, 167);
}

