@import 'variables';

.selectedDashboard{
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    overflow:hidden;

    &__columnFlex {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    &__headerNav{
        position: absolute;
        top:18px;
        right:18px;

        @media(max-width:$tabletBreakPoint) {
            position:relative;
            padding:0.5rem 0;
            top:0;
            right:0;
            width:100%;
            display:flex;
            justify-content: right;
        }
    }


    &__innerBorderLayer{
        width:100%;
        height:100%;
        border:$globalBorder;
        border-radius: $globalBorderRadius;
        background-color: $primaryLight;
        display:flex;
        flex-direction: column;
        overflow: hidden;
    }

    &__nav{
        width:100%;
        display:flex;
        flex-direction: column;
        align-items: flex-start;

        &__inner{
            width:100%;
            display:flex;
            align-items: flex-start;
        }

    }

    &__navBorder{
        display:flex;
        align-items: flex-start;
        border:0;
    }

    &__tabsMobile{
        display:none;
        @media(max-width:$tabletBreakPoint){
            display:block;
            border-bottom:$globalBorder;
            width:100%;
        }
    }

    &__tabs{
        width:100%;
   /*      display:flex;
        flex-direction:row;
        flex-wrap: wrap;
        align-items: flex-start;
        border-bottom:$globalBorder;
        width:100%;

        @media(max-width:$tabletBreakPoint){
            display:none;
        } */
    }


    &__noQueryfilter{
        width:100%;
        display:flex;
        flex-direction:row;
        flex-wrap: wrap;
        align-items: flex-end;
        border-bottom: $globalBorder;

        app-select-input {
            padding:0.5rem;
        }

        app-date-input {
            padding:0.5rem;
        }

        app-checkbox{
            padding:0.5rem;
        }
    }

    &__filterButton {
        min-width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: $globalBorder;
    }

    &__filter{
        width:100%;
        display:flex;
        flex-direction:row;
        flex-wrap: wrap;
        align-items: flex-end;
        border-bottom: $globalBorder;

        &__wrapper {
            display: flex;
        }

        app-select-input {
            padding:0.5rem;
        }

        app-date-input {
            padding:0.5rem;
        }

        app-checkbox{
            padding:0.5rem;
        }

        @media(max-width:$tabletBreakPoint){
            display:none;
        }

        &__select{
            display:flex;
            align-items: flex-end;
        }

    }

    &__filterMobile{
        width:100%;
        display:flex;
        flex-direction: column;
        //padding:0.5rem;


        app-select-input {
            padding:0;
        }

        app-date-input {
            padding: 0;
        }

        @media(min-width:$tabletBreakPoint){
            display:none;
        }

        &__select{
            padding:0;
        }

    }


    &__toggleChartsSimple{
        width:100%;
        display:flex;
        justify-content: right;
        align-items: center;
        padding:0.5rem;

        app-main-button{
            padding-left:0.5rem;
        }

        @media(max-width:$tabletBreakPoint){
            width:100%;
            padding:0.5rem;
            display:flex;
            justify-content: space-around;
            align-items: center;
        }
    }

    &__content{
        width:100%;
        height:100%;
        overflow:auto;

    }


    &__noDataConatiner{
        display:flex;
        align-items: center;
        justify-content: center;
        height:80px;
        width:100%;
    }
}