.component{
    width:100%;
    //height:100%;
    border:$globalBorder;
    border-radius:$globalBorderRadius;
    background-color: $primaryLight;


    &__header{
        display:flex;
        align-items: center;
        justify-content: space-between;
        width:100%;

        @media(max-width:$tabletBreakPoint){
            display:flex;
            flex-direction: column;
            align-items: flex-start;
        }

    }

    &__nav{
        display:flex;
        align-items: center;
        margin-top: 1rem;
        margin-right:16px;

        app-checkbox{
            padding:0.5rem;
        }

        app-main-button{
            padding:0 0.5rem;
        }

        @media(max-width:$tabletBreakPoint){
            width:100%;
            display:flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0 16px;

            app-filter{
                padding:0.5rem 0;
                width:100%;
            }

            app-main-button{
                padding-left:0;
                padding-right:0.5rem;
            }
        }

    }


    &__inner{
        padding:16px;
        //margin:16px;
        //height:100%;
    }

}