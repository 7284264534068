.everleadMobileTab {
    width: 100%;
    padding: 0.5rem;

    &__label {
        display: none;
    }

    &__valueWrapper {
        width: 100%;
        background-color: $primaryLight;
        color: $primaryLight !important;
        height: 40px;
        padding: 10px 8px;

        .selectTab__box {
            position: relative;
            display: inline-block;
            width: 100%;
            color: $secondary;
            border-bottom: none !important;
            background-color: $primaryLight;
            width: 100%;
            padding-right: 30px;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            border: none !important;
            border-bottom: none !important;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgZmlsbD0iIzU0NjVmZiIgdmlld0JveD0iMCAwIDI1NiAyNTYiPjxwYXRoIGQ9Ik0yMTMuNjYsMTAxLjY2bC04MCw4MGE4LDgsMCwwLDEtMTEuMzIsMGwtODAtODBBOCw4LDAsMCwxLDUzLjY2LDkwLjM0TDEyOCwxNjQuNjlsNzQuMzQtNzQuMzVhOCw4LDAsMCwxLDExLjMyLDExLjMyWiI+PC9wYXRoPjwvc3ZnPg==') !important;
            background-repeat: no-repeat;
            background-position: right 10px center;
            background-size: 12px 12px;
            cursor: pointer;
            font-size: 14px;

            &:focus {
                outline: none !important;
            }

            &:active {
                outline: none !important;
            }
        }

        .selectTab__box::-ms-expand {
            display: none
        }


    }

}


.everleadSelectWrapper{
    position: relative;
    width:100%;


    &.disabled{
        color:$fontDark !important;
    }

    &.error{
        color:$functionRedColor !important;
    }


    &__label{
        position:relative;
        //top:12px;
        top:14px;
        left:8px;
        color:$fontBlack !important;
        font-size: 12px;
        font-weight: 400;
        padding: 0 0.2rem;
        margin: 0;
        background-color: $primaryLight;
        text-transform: uppercase;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
        //text-wrap: wrap;
        //display:flex;

        &.disabled{
            color: $disabledGray !important;
            font-weight: 700;
        }

        &.error{
            color:$functionRedColor !important;
        }
    }


    &__valueWrapper{
        border: $globalBorder;
        border-radius: $globalBorderRadius;
        display:flex;
        align-items: center;
        padding:10px 8px 10px 8px;
        height:30px;
        width:100%;
        background-color: $primaryLight;

        &:hover{
            border:$globalBorderHover;
        }

        &.error{
            border:1px solid $functionRedColor;
        }

        &.disabled{
            border:$globalBorder;
            cursor:not-allowed;
        }

        .select__box{
            position: relative;
            display: inline-block;
            width: 100%;
             

            select{
                width:100%;
                padding-right:30px;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                border: none;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgZmlsbD0iIzU0NjVmZiIgdmlld0JveD0iMCAwIDI1NiAyNTYiPjxwYXRoIGQ9Ik0yMTMuNjYsMTAxLjY2bC04MCw4MGE4LDgsMCwwLDEtMTEuMzIsMGwtODAtODBBOCw4LDAsMCwxLDUzLjY2LDkwLjM0TDEyOCwxNjQuNjlsNzQuMzQtNzQuMzVhOCw4LDAsMCwxLDExLjMyLDExLjMyWiI+PC9wYXRoPjwvc3ZnPg==');
                background-repeat:no-repeat;
                background-position: right 10px center;
                background-size: 12px 12px;
                cursor:pointer;
                font-size: 14px;
                color: $fontDark;

                &:disabled{
                    cursor: not-allowed;
                    color:$disabledGray !important;
                }
            }

            select::-ms-expand{
                display:none
            }

            select:focus{
                outline: none;
            }

        

        }

        .select__box:focus{
            outline:none;
        }


    }
}