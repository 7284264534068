.userManagement {
    &__fullInfoMode {
        display: flex;
        align-items: center;
        margin: 10px 0;
        border-bottom: 1px dotted gray;
        &__button {
            max-width: 80px;
            width: 80px;
            display: flex;
            align-items: center;
        }
        &__text {
            max-width: 50%;
            width: 50%;
            display: flex;
            align-items: center;
            label { margin: 0; }
        }
        &__dropdown {
            max-width: 50%;
            width: 50%;
            display: flex;
            align-items: center;
            border: 0;
            select { width: 100%; margin: 0; border: 0; }
        }
    }

    &__wrapper {
        display: flex;
        width: 100%;
        height: calc(100% - 0px);
        gap: 24px;

        border:$globalBorder;
        border-radius: $globalBorderRadius;

        @media(max-width: $desktopBreakPoint) {
            display: block;
        }
        flex-direction: row;
    }

    &__blockwrapper {
        margin: 15px 0 15px 0;
    }

    &__left {
        width: 50%;
        overflow-y: auto;
        height: 100%;
        @media(max-width: $desktopBreakPoint) {
            height: auto;
            width: 100%;
        }

        overflow-y: auto;

        &__input {
            width: 100%;
            margin-left: 10px;
        }

        &__header {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 5px;
        }
    }

    &__sectionHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        // background-color: gainsboro;
        margin: 10px 2px;
        height: 50px;
        //border: 1px solid $boxBorderGrey;
        //border-radius: 5px;
        border-bottom:$globalBorder;
        font-size: 14px;

        app-filter{
            width:100%;
            padding:0.5rem 0!important;
        }

        @media(max-width:$tabletBreakPoint){
            display:flex;
            flex-direction: column;
            align-items: flex-start;
            width:100%;
            height:80px;
            margin:0;
            padding:0;

            app-filter{
                width:100%;
                padding:0.5rem !important;

            }
        }

        &__label {
            width: 300px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @media(max-width:$tabletBreakPoint){
                padding:0.5rem;
                app-filter{
                    width:100%;
                    padding:0.5rem !important;
                    /* border:1px solid red; */
                }
            }
        }

        &__button {
            margin: 0 10px;
        }

        &__nav{
            display:flex;
            align-items: center;

            app-jj-icons{
                padding:0.5rem;
            }

            app-main-button{
                padding:0 0.5rem;
            }

            app-filter{
                padding:0.5rem 0;

                @media(max-width:$tabletBreakPoint){
                    width:100%;
                    padding-right:0.5rem;
                }
            }


            @media(max-width:$tabletBreakPoint){
                width:100%;
        
            }
        }
    }

    &__right {
        width: 50%;
        padding: 0 10px;
        @media(max-width: $desktopBreakPoint) {
            width: 100%;
            padding: 0;
        }
    }

    &__itemContainer {
        overflow-y: auto;
    }

    &__moveIcon {
        fill: $blueColor;
        cursor: pointer;
    }

    &__item {
        //border-radius: 10px;
        background-color: white;
        margin: 0 5px 5px 0;
        //border: 1px solid gainsboro;
        display: flex;
        flex-direction: row;
        &__projectInfo {
            display: flex;
            align-items: center;
            color: $coralColor;
            font-size: small;
            margin: 0;
            position: absolute;
            z-index: 10;
            top: -12px;
            background-color: white;
            border: 1px solid gainsboro;
            border-radius: 5px;
            left: 20px;
            max-width: calc(100% - 80px);
            width: calc(100% - 80px);
            padding: 0 20px;
            &__inner {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                margin: 0;
            }
        }
        &__container {
            // margin: 0 5px 5px 0;
            display: flex;
            flex-direction: row;
            position: relative;
        }
    

        &__containerreverse {
            flex-direction: row-reverse;
            .userManagement__item__move {
                border-radius: 10px 0 0 10px;
            }
            .userManagement__item__id {
                border-radius: 0 10px 10px 0;
            }
        }

        &__move {
            min-width: 70px;
            padding: 0 15px;
            border-radius: 0 10px 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__id {
            min-width: 100px;
            max-width: 100px;
            padding: 0 15px;
            //border-radius: 10px 0 0 10px;
            color: $secondary;
            display: flex;
            justify-content: center;
            align-items: center;
            svg-icon {
                margin: 0 5px;
            }
            &__selected {
                background-color: $coralColor !important;
            }
        }

        &__name {
            padding: 5px 10px;
            width: 100%;
            text-overflow: ellipsis !important;
            overflow: hidden;
            white-space: nowrap !important;
            min-height: 18px;
            &__email {
                font-size: 12px;
                color: $secondary;
            }
        }
    }

    &__details {
        &__item {
            display: flex;
            flex-direction: row;
            padding: 4px;
            align-items: center;
            @media(max-width: $desktopBreakPoint) {
                flex-direction: column;
            }

            &__label {
                width: 200px;
                @media(max-width: $desktopBreakPoint) {
                    width: 100%;
                }
            }

            &__input {
                width: calc(100% - 200px);
                @media(max-width: $desktopBreakPoint) {
                    width: 100%;
                }
            }

            &__button {
                width: 200px;
                @media(max-width: $desktopBreakPoint) {
                    width: 100%;
                }
            }

            &__description {
                width: calc(100% - 200px);
                padding-left: 10px;
                @media(max-width: $desktopBreakPoint) {
                    width: 100%;
                    padding-left: 0;
                }
            }
        }

        &__options {
            margin: 5px 0;
            padding: 5px 0 5px 30px;
            @media(max-width: $desktopBreakPoint) {
                padding: 5px;
            }            
            border: 1px solid $boxBorderGrey;
            fill: $secondaryShade5;
            border-radius: 5px;
            align-items: center;
            display: flex;
       }

        &__header {
            display: flex;
            flex-direction: row;
            // padding: 0 0 0 30px;
            margin-top: 10px;
            // background-color: $blueColor;
            // color: white;
            border-radius: 5px;
            align-items: center;
            width:100%;

            &__left {
                fill: white;
                width: 60px;
            }

            &__right {
                width: 100%;
            }
        }
    }
}

.projectUser {
    &__wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }
    &__header {
      display: flex;
      flex-direction: column;
      margin-bottom: 12px;

      @media(max-width: $tabletBreakPoint) {
        width: 100%;
        padding-bottom:0.5rem;
    }

      &__label {
        width: 300px;
        @media(max-width: $desktopBreakPoint) {
            width: 100%;

        }
      }

      &__item {
        display: flex;
        flex-direction: row;
        @media(max-width: $desktopBreakPoint) {
            flex-direction: column;
        }

        &__button{
            align-self: flex-end;
            position: absolute;
            top: 16px;
            right: 16px;


            @media(max-width:$tabletBreakPoint){
                position:relative;
                top: 0;
                right:0;
                align-self: flex-start;
            }
        }
      }

      &__dropdown {
        width: calc(100% - 300px);
        @media(max-width: $desktopBreakPoint) {
            width: 100%;
        }
      }

      &__userList {
        overflow-y: auto;
        max-height: calc(100% - 70px);

        border:$globalBorder;
/*         border: 1px solid $boxBorderGrey;
        border-width: 0 1px 0 1px; */
        overflow: hidden;
        overflow-y: auto;
      }
    }
  }
