.incomingCall {
    &__wrapper {
        position: absolute;
        z-index: 30000;
        right: 30px;
        top: 130px;
        width: 400px;
        min-height: 200px;

    }

    &__inner {
        width: 100%;
        height: 100%;
        border: 5px solid $blueColor;
        background-color: whitesmoke;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        // border-radius: 30px;
        // padding-bottom: 10px;

        &__wait {
            padding: 0 30px;
        }

        &__header {
            margin-bottom: 10px;
            border-bottom: 1px solid gainsboro;
            padding: 5px 0 0 50px;
            font-weight: 700;
            height: 30px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            background-color: white;

            &__close {
                width: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &__caller:hover {
            background-color: $coralColor;
            color:white;
            cursor: pointer;
            label {
                cursor: pointer;
            }
            .incomingCall__inner__caller__accountlabel {
                color:white;
            }
            .incomingCall__inner__caller__phonelabel {
                color:white;
            }
        }
        &__caller {
            height: 100%;
            box-shadow: 0 4px 8px 0 RGB(0 0 0 / 20%);
            margin: 5px;
            padding: 10px;
            background-color: white;

            &__callerNumber {
                width: 100%;
                text-align: center;
            }
            &__wrapper {
                max-height: 400px;
                overflow-y: auto;
            }

            &__waitIcon {
                margin-right: 5px;
            }

            &__contactlabel {
                font-weight: 700;
                width: 100%;
                // margin-bottom: 10px;
            }

            &__accountlabel {
                font-size: smaller;
                color: gray;
                width: 100%;
                font-weight: 700;
            }

            &__phonelabel {
                font-size: smaller;
                color: gray;
                width: 100%;
            }

            &__foundContacts {
                font-size: smaller;
                font-weight: 700;
                border-bottom: 1px solid gainsboro;
                margin-bottom: 10px;
                text-align: center;
            }
        }
        &__dontShowAgain {
            cursor: pointer;
            padding: 0 20px;
            background-color: $coralColor;
            color: white;
            text-align: center;
            label {
                cursor: pointer;
            }
        }
        &__accept {
            margin: 10px 0;
            &__acceptRow {
                display: flex;
                flex-direction: row;
                align-items: center;
                background-color: $greenColor;
                height: 40px;
                justify-content: center;

                &__acceptButton {
                    margin-right: 5px;
                }
                &__acceptText {
                    fill: white;
                    color:white;
                }
            }

            &__loadContact {
                display: flex;
                align-items: center;
                cursor: pointer;
                background-color: $coralColor;
                justify-content: center;
                color: white;
                margin-top: 5px;
                margin-bottom: 1px;

                &__actionText {
                    text-align: center;
                    width: 100%;
                }
                &__actionButton {
                    background-color: $coralColor;
                    margin-right: 10px;
                    height: 100%;
                    fill: white;
                    width: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        position: absolute;
        left: -10px;
        top: -10px;
        border-radius: 50%;
        background-color: $coralColor;
        color: white;
        z-index: 10;

        &__wrapper {
            position: relative;
        }
    }
}
