@import 'variables';

$everleadCoral: $coralColor;
$everleadGreen: $greenColor;
$everleadBlue: $blueColor;

$miniButtonHeight: 26px;

.label {
    &__nodefaultstyling {
        padding: 0px;
        margin: 0px;
    }
}

.angMatFormField {
    height: 48px;
}

.everleadButton {
    &__ghost {
        border: 0px;
        cursor: pointer;
        background-color: transparent;
        outline: none;
    }
    &__ghost:hover {
        color: red;
    }
    &__ghost:disabled {
        cursor: default;
        color: gray;
    }
}

body .ui-widget-overlay {
    background-color: rgba(0, 0, 0, 0.3);
}

.everleadGreen {
    color: $everleadGreen;
    fill: $everleadGreen;
}

.everleadGreenBackground {
    background-color: $everleadGreen;
}

.everleadCoral {
    color: $secondary !important;
    fill: $secondary !important;
}

.everleadCoralHover:hover {
    color: $secondary !important;
    fill: $secondary !important;
}

.everleadCoralBackground {
    background-color: $secondary !important;
    color: white;
}

.everleadMagentaBackgroundColor {
    background-color: $secondary !important;
}

.everleadBlueColor {
    color: $everleadBlue !important;
    fill: $everleadBlue !important;
}

.everleadBlueBackgroundColor {
    background-color: $everleadBlue !important;
    color:white;
}

.autoOverflowY {
    overflow-y: auto;
}

.autoOverflowX {
    overflow-x: auto;
}

.customControlValueWrapperTextArea {
    border: 0px solid gray;
}

.customControlValueWrapper {
    // border: 1px solid gray;
    // background-color: $blueColor;
    //fill: white;
    height: 26px;
    input { height: 100%; }
    select { 
        height: 100%; 
        //border-bottom: 1px solid transparent; 
    }

    app-extended-dropdown {
        div { height: 26px; }
    }
    svg-icon {
        fill: white !important;
        color: white !important;
    }

}
/* .customControlValueWrapper:hover {
    input { 
        //border-bottom: 1px solid gray; 
        margin-bottom: -1px; 
    }

    select { 
        border-bottom: 1px solid gainsboro; 
    }
} */

.everleadCustomControlMiniButton {
    border-radius: 50%;
    background-color: $blueColor;
    border: 1px solid gray;
    padding: 2px;
    min-width: $miniButtonHeight;
    min-height: $miniButtonHeight;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: $miniButtonHeight;
    max-height: $miniButtonHeight;
    margin: 0 1px;
    i { margin: 0; margin-bottom: -1px; }
}

.fa-everleadSmall {
    font-size: 12px;
}

.everleadCustomButton, .jjButtonStyle {

    //border: 2px solid $coralColor;
    background-color: $secondary !important;
    color:$primaryLight !important;
    min-height: 30px;
    border-radius: $globalBorderRadius;
    padding: 0 20px;
    margin: 5px;
    border:none
    // background: linear-gradient(90deg, $coralColor 0%, $coralColor 50%, rgba(237, 92, 115, 0.6) 65%, rgba(237, 92, 115, 0.3) 80%);
}

.everleadSectionBox {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    background-color: white;
    border-radius: 10px;
    margin: 0 5px 15px 5px;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

.everleadCustomButton:hover:enabled {
    //border: 2px solid $blueColor;
    //background-color: $coralColor;
    // opacity: 0.9;
    // font-weight: 700;
}

.everleadCustomButton:disabled {
    background-color: $primaryDark !important;
    color: $fontMidColor !important;
    background: transparent;
    border: 1px solid $boxBorderGrey;
}

.quickSearchSelectionFound {
    color: $everleadCoral;
    font-weight: 700;
}

.featureNotAvailableDiv {
    height: 100%;
} 