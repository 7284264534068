/*  table {
    background-color: $primaryLight !important;
    border-collapse: separate !important;
}



.mdc-data-table__table{
    border-spacing:  20px !important;
}



.mat-row{

}



td.mat-mdc-cell {
    background-color: $primaryLight !important;
    color: $fontDark;  
    border-left:4px solid $secondary; 
}


.mat-mdc-header-row {
    font-weight: bold !important;
    background-color: $primaryLight !important;
    position: sticky;
    top: -5px;
    color: $secondary !important;

} */


.mat-mdc-header-cell, .mat-mdc-cell {
    border-bottom-color: transparent !important;
    border-bottom-width: transparent !important;
    border-bottom-style: transparent !important;
    letter-spacing: 0;    
    line-height: inherit;
    text-align: left !important;
}
 

