.dataFilter {
    &__groupItemMember {
        &__header {
            background-color: gainsboro;
            color: $blueColor;
            i { margin: 0 5px; }
            display: flex;
            align-items: center;
        }
        &__sublevel {
            display: flex;
            align-items: center;
            &__item {
                display: flex;
                align-items: center;
                width: 100%;
                input { margin: 0 5px; }
                label { margin: 0; }
            }
        }
    }

    &__member {
        &__infoLine {
            margin: 3px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: $tabletBreakPoint) {
                flex-direction: column;
                position: relative;
                padding-right: 30px;
                margin: 5px 0 15px;
            }

            label {
                margin: 0;
            }

            &__operatorLeft {
                width: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &__largeInfo {
                padding: 0 10px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                // max-width: 35%;
                input { width: 100%; }
                label { width: 100%; }
                i { margin: 0 5px; }
                &__memberSection {
                    @media (min-width: $tabletBreakPoint) {
                        max-width: 400px;
                    }
                }
                &__segment1 {
                    width: 100%;
                    input { width: 100%; }
                }

                &__segment2 {
                    width: 100%;
                    margin-left: 5px;
                    input { width: 100%; }
                }
                &__subItem {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    align-items: center;

                    app-datafilter-groupitem-member-details {
                        width: 100%
                    }
                    select {
                        width: 100%;
                        height: 100%;
                        // background-color: whitesmoke;
                        border: 1px solid gainsboro;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 5px;
                        outline: 0;
                        border-width: 0 0 1px 0;
                    }
                }
            }

            &__operator {
                min-width: 200px;
                width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: center;
                border-radius: 5px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                select {
                    outline: 0;
                }
                &__background {
                    background-color: $coralColor;
                    color: white;
                }

                @media (max-width: $tabletBreakPoint) {
                    margin-top: 8px;
                }
            }

            &__buttons {
                height: 100%;
                display: flex;
                width: 40px;
                align-items: center;
                justify-content: space-between;

                @media (max-width: $tabletBreakPoint) {
                    position: absolute;
                    height: auto;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            &__button {
                border: 0px solid;
                outline: 0;
                margin-right: 5px;
                color: black;
                background-color: transparent;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
            }

            &__wrapper {
                display: flex;
                flex-direction: column;
            }
        }
    }

    &__criteria {
        &__header {
            position: absolute;
            right: 0;
            top: -15px;
            border: 1px solid gainsboro;
            padding: 0px 3px;
            border-radius: 5px;
            font-size: small;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid gainsboro;
            background-color: whitesmoke;
            // height: 25px;
            // margin-bottom: 10px;

            &__operator {
                height: 100%;
                min-width: 150px;
                display: flex;
                align-items: center;

                select {
                    height: 25px;
                    border-bottom: 0px solid;
                    background-color: gainsboro;
                    margin-left: 10px;
                    padding-left: 10px;
                    outline: 0;
                }

                label {
                    margin: 0 5px 0 0;
                }
            }

            &__buttons {
                height: 100%;
                display: flex;
                align-items: center;
                width: 100%;

                label {
                    margin: 0;
                }

                &__button {
                    border: 0px solid;
                    outline: 0;
                    margin-right: 5px;
                    background-color: transparent;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                }
            }
        }
    }


    &__infoLine {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: small;
        i { margin: 0 5px; }
        &__wrapper {
            margin: 10px;
        }

        &__item {
            display: flex;
            align-items: center;
            width: 100%;

            &__wrapper {
                display: flex;
                align-items: center;
                width: 100%;
            }
        }
        label {
            margin: 0;
            max-width: 350px;
            min-width: 350px;
            @media (max-width: $tabletBreakPoint) {
                min-width: 235px;
                max-width: 235px;
            }
        }
        input {
            width: 100%;
        }
        select {
            width: 100%;
        }
        i {
            margin: 0 5px;
            min-width: 10px;
        }

        @media (max-width: $mobileBreakPoint) {
            flex-wrap: wrap;

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }

    &__mainPanel {
        &__criteriaGroupSpacer {
            display: flex;
            align-items: center;
            justify-content: center;
            color: gray;
            height: 20px;
            border-radius: 5px;
        }

        &__operator {
            &__inner {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 20px;

                label {
                    margin: 0;
                }
            }
        }


        &__preview {
            display: flex;
            align-items: center;
            justify-content: space-between;

            button {
                width: 150px;
            }

            label {
                margin: 0;
                padding: 0 10px;
                width: 100%;
            }
        }

        &__header {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: $blueColor;
            background-color: gainsboro;
            height: 25px;

            label {
                margin: 0;
                padding-left: 10px;
            }

            &__button {
                border: 0px solid;
                outline: 0;
                margin-right: 5px;
                color: $blueColor;
                background-color: transparent;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
            }
        }
    }
}

.dataFilterGroupOperator {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &__inner {
        width: 100px !important;
    }

    @media (max-width: $mobileBreakPoint) {
        margin-bottom: 10px;
    }
}
