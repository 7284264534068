.dragItem {
    margin-bottom: 2px; 
    background-color: whitesmoke;
    border: 1px solid RGB(218, 207, 207); 
    padding: 2px;
}

.dragDropitem-container {
    width: 400px;
    max-width: 100%;
    margin: 0 25px 25px 0;
    display: inline-block;
    vertical-align: top;
  }
  
  .dragColumnDiv {
    overflow-y: hidden;
  }

  .fixedPosition {
    position: absolute;
  }

  .dragDropitem-list {
    border: solid 1px #ccc;
    min-height: 60px;
    background: white;
    border-radius: 4px;
    overflow: auto;
    display: block;
    height: 400px;
    max-height: 400px;
  }
  
  .dragDropitem-box {
    padding: 10px 10px;
    border-bottom: solid 1px #ccc;
    background-color: whitesmoke;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    //box-sizing: border-box;
    cursor: move;
    background: white;
  }
  
  .dragDropitem-noflexbox {
    padding: 1px;
    border-bottom: solid 1px #ccc;
    background-color: whitesmoke;
    cursor: move;
    background: white;
  }

  .cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    //box-shadow:none !important;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
                0 8px 10px 1px rgba(0, 0, 0, 0.14),
                0 3px 14px 2px rgba(0, 0, 0, 0.12);

  }
  
  .cdk-drag-placeholder {
    opacity: 0;
  }
  
  .dragDropitem-box:last-child {
    border: none;
  }

  .dragDropMoveAllItemsButton {
    width: 100%;
  }

  .dragDropfilterInput{
    width: 80%;
  }
  
  .dragDropfilterInfo {
    width: 10%;
  }

  .grabber {
    cursor: col-resize;
    min-width: 5px;
    height: 100%;
    background-color: transparent;
 }