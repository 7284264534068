@import 'variables'; 

.buttonMargin{
    margin:0 0.5rem;

}

.emailContentList{
    border:$globalBorder;
    border-radius: $globalBorderRadius;
    height: calc(100vh - 17rem);
    width:100%;
    overflow-y: auto;
    color:$secondary;
    font-weight: $fontBold;

    &__row {
        width: 100%;
        padding:1rem 0.5rem;
        background-color: $primaryDark;

        &:nth-child(even){
            background-color: $primaryLight;
        }

        &:hover{
            background-color:$secondaryShade5;
        }
    }
    &__content {
        display: flex;
        align-items: center;
        &__id {
            min-width: 40px;
            width:40px;
        }
    }
}


.filter{
    border: $globalBorder;
    border-radius: $globalBorderRadius;
    width:100%;
    display:flex;
    margin-left:1.2rem;

    .filterWrapper{
        padding:12px 16px;
        height:35px;
        display:flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        width:100%;

        input{
            border:none!important;
            background-color: none;
            width:100%;
        }
    }
}